import { forwardRef } from 'react';
import { Button, Typography } from 'antd';
import {
  LOGIN_PAGE,
  TRY_AGAIN,
  VERIFYING,
  REGISTER_BTN_MSG,
  REGISTER_MSG,
} from 'constants';
import passkeyImage from 'assets/images/passkey.svg';
import * as config from 'config/config';

const StepOne = ({
  animateRef,
  isMobile,
  isMac,
  loading,
  next,
  query,
  onSignInSecondButton,
  isInitialLoad,
}) => {
  return (
    <div
      ref={animateRef}
      style={{ display: 'flex', flexDirection: 'column', minHeight: '220px' }}>
      <div
        style={{
          display: 'inline-flex',
          margin: 'auto',
          flexDirection: 'column',
        }}>
        <Button
          type="primary"
          loading={loading}
          htmlType="submit"
          disabled={loading}
          style={{
            height: isMobile ? '40px' : '45px',
            fontSize: isMobile ? '0.9rem' : '1.1rem',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: isMobile ? '12px' : '16px',
            paddingLeft: isMobile ? '12px' : '16px',
            borderRadius: '4px',
            background: '#0186c2',
          }}>
          {!loading && (
            <div
              style={{
                width: isMobile ? 24 : 30,
                height: isMobile ? 24 : 30,
                display: 'inline-block',
                marginRight: isMobile ? '12px' : '16px',
              }}>
              <img
                alt="security Key"
                src={passkeyImage}
                layout="fixed"
                width={isMobile ? 24 : 30}
                height={isMobile ? 24 : 30}
                style={{ objectPosition: 'left', objectFit: 'scale-down' }}
              />
            </div>
          )}
          <Typography.Text style={{ fontSize: '1.125rem', color: 'white' }}>
            {loading && !isInitialLoad
              ? VERIFYING
              : LOGIN_PAGE.LOGIN_WITH_SECURITY_KEY}
          </Typography.Text>
        </Button>
        {isMac && (
          <Button
            type="default"
            loading={loading}
            htmlType="button"
            onClick={onSignInSecondButton}
            disabled={loading}
            style={{
              height: isMobile ? '40px' : '45px',
              fontSize: isMobile ? '0.9rem' : '1.1rem',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              paddingRight: isMobile ? '12px' : '16px',
              paddingLeft: isMobile ? '12px' : '16px',
              marginTop: '16px',
              borderRadius: '4px',
            }}>
            <span>
              {loading && !isInitialLoad
                ? VERIFYING
                : LOGIN_PAGE.LOGIN_WITH_IDMELON_AUTHENTICATOR}
            </span>
          </Button>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}>
        {!!query?.sp?.toLowerCase()?.includes('idmelon') || !query?.sp ? (
          <span
            style={{
              fontSize: '1rem',
            }}>
            {REGISTER_MSG}
            <a
              href={config.PANEL_URL + '/auth/register'}
              style={{
                paddingLeft: '0',
                marginBottom: '30px',
                color: '#0186c2',
              }}>
              {REGISTER_BTN_MSG}
            </a>
          </span>
        ) : (
          <div></div>
        )}
        <Button
          type="link"
          onClick={() => next()}
          style={{
            fontSize: '1rem',
            padding: 0,
          }}>
          <span className="text-primary">
            {LOGIN_PAGE.NO_SECURITY_KEY}&nbsp;
          </span>
          {LOGIN_PAGE.SIGN_IN_OPTION}
        </Button>
      </div>
    </div>
  );
};
export default StepOne;
